import React from 'react';
import intl from '$intl';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import InvoiceModel from '$business/models/invoice';
import { Div, Space, SPACE, H2 } from '$gstyles';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { submitCardPayment } from '$business/redux/invoice/actions';

import { PAYMENT_FORM } from '$components/forms/paymentForm';
import PaymentModel, { defaultPayment } from '$business/models/payment';

interface PaymentSectionProps {
  invoice: InvoiceModel;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ invoice }) => {
  const dispatch = useDispatch();
  const total = (invoice?.tax || 0) + invoice.amount;
  const totalCurrency = intl('PRICE', { amount: total });

  //const initialValues:PaymentModel = defaultPayment;
  const initialValues: PaymentModel = {
    ...defaultPayment,
    invoiceId: invoice.invoiceId,
    email: invoice.email,
    shouldEmail: true,
    country: 'UNITED_STATES',
    amount: total,
    saveCard: true,
    isMit: false,
  };

  // Methods
  const t = key => intl(`SCREEN.PAYMENT.${key}`);
  const validateForm = values => input.validateError(PAYMENT_FORM, values);
  const onSubmit = values => {
    dispatch(submitCardPayment(values));
  };

  const PriceItem = ({ label, amount }) => {
    return (
      <Flex justifyContent="space-between" paddingTop={SPACE.LARGE}>
        <H2 padding={0}>{t(label)}</H2>
        <H2 padding={0}>{intl('PRICE', { amount })}</H2>
      </Flex>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <div className="form-container">
            <Space.All padding={SPACE.LARGE}>
              <h4>{t('TITLE')}</h4>
              <Div margin={`${SPACE.LARGE} 0`} className="subtitle">
                {t('SUBTITLE')}
              </Div>
              <FormSection formik={formik} FORM={PAYMENT_FORM} />
              <PriceItem label="TOTAL" amount={total} />
              <Div margin={`${SPACE.LARGE} 0`}>
                <Space.Top padding={SPACE.LARGE} />
                <Button fullWidth onClick={formik.handleSubmit} disabled={!formik.isValid}>
                  {intl('SCREEN.PAYMENT.SUBMIT_BUTTON', { amount: totalCurrency })}
                </Button>
              </Div>
            </Space.All>
          </div>
        );
      }}
    </Formik>
  );
};

export default PaymentSection;
